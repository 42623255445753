<template>
    <v-form ref="form" v-model="valid" @submit.prevent="send">
        <v-layout wrap>
            <v-flex xs12 md4>
                <v-switch v-model="input.incharge" label="INCHARGE" class="my-0 py-0" />
            </v-flex>
            <v-flex xs12 md4>
                <v-switch v-model="input.sendUnlockBeforeConnect" label="Envio de comando de desbloqueio com carro desconectado" class="my-0 py-0" />
            </v-flex>
            <v-flex xs12 md4>
                <v-switch v-model="input.hubject" label="Integração com Hubject" class="my-0 py-0" />
            </v-flex>
            <v-flex xs12>
                <v-alert border="left" colored-border color="info" elevation="5">
                    <h5 class="info--text">Potência</h5>
                    <v-layout wrap>
                        <v-flex xs12>
                            <v-text-field v-model="input.powerMax" :rules="[rules.required, rules.range(1, 180)]" label="Potência máxima" suffix="kW" type="number" required />
                        </v-flex>
                        <v-flex xs12 md6>
                            <v-switch v-model="input.powerControl" label="Liberação de potência ao conectar" class="my-0 py-0" />
                        </v-flex>
                        <v-flex xs12 md3 v-if="input.powerControl">
                            <v-text-field v-model="input.powerFree" :rules="[rules.required, rules.range(1, 10)]" label="Potência Grátis" suffix="kW" type="number" required />
                        </v-flex>
                        <v-flex xs12 md3 v-if="input.powerControl">
                            <v-text-field v-model="input.powerFreeTime" :rules="[rules.range(1, 60)]" label="Tempo Grátis" suffix="minutos" type="number" required />
                        </v-flex>
                    </v-layout>
                </v-alert>
            </v-flex>
            <v-flex xs12>
                <v-alert border="left" colored-border color="info" elevation="5">
                    <h5 class="info--text">Política de preço</h5>
                    <v-layout wrap v-if="input.payment">
                        <v-flex xs12>
                            <v-select v-model="input.payment.pricePolicy" :items="policies" label="Política" class="my-0 py-0" clearable />
                        </v-flex>
                        <v-flex xs12 md6 v-if="input.payment.pricePolicy">
                            <v-text-field v-model="input.payment.price" :rules="[rules.required, rules.money]" label="Valor na Ativação" v-mask="['#.##', '##.##', '###.##']"
                                return-masked-value prefix="R$" type="number" required />
                        </v-flex>
                        <v-flex xs12 md6 v-if="input.payment.pricePolicy == 'PAYMENT_PER_CONSUMPTION'">
                            <v-text-field v-model="input.payment.priceKwh" :rules="[rules.required, rules.money]" label="Preço por kWh" v-mask="['#.##', '##.##', '###.##']"
                                return-masked-value prefix="R$" type="number" required />
                        </v-flex>
                        <v-flex xs12 md6 v-if="input.payment.pricePolicy == 'PAYMENT_PER_CONSUMPTION'">
                            <v-text-field v-model="input.payment.payTriggerStop" :rules="[rules.range(1, 100)]" label="Porcentagem para parar o carregamento"
                                v-mask="['#', '##', '###']" return-masked-value prefix="%" type="number" required />
                        </v-flex>
                        <v-flex xs12 md6>
                            <v-text-field v-model="input.payment.payPartnerTransfer" :rules="[rules.range(1, 100)]" label="Porcentagem parceiro" v-mask="['#', '##', '###']"
                                return-masked-value prefix="%" type="number" required />
                        </v-flex>
                    </v-layout>
                </v-alert>
            </v-flex>
            <v-flex xs12>
                <v-alert border="left" colored-border color="info" elevation="5">
                    <h5 class="info--text">Stripe</h5>
                    <v-layout wrap>
                        <v-flex xs12 md6>
                            <v-text-field v-model="input.stripe.private" label="Chave privada" />
                        </v-flex>
                        <v-flex xs12 md6>
                            <v-text-field v-model="input.stripe.public" label="Chave pública" />
                        </v-flex>
                        <v-flex xs12 md6>
                            <v-text-field v-model="input.stripe.partner" label="Chave do parceiro (Connect)" />
                        </v-flex>
                    </v-layout>
                </v-alert>
            </v-flex>
            <v-flex xs12>
                <v-alert border="left" colored-border color="info" elevation="5">
                    <h5 class="info--text">Pagar.me</h5>
                    <v-layout wrap>
                        <v-flex xs12>
                            <v-text-field v-model="input.pagarme.private" label="Chave da API" />
                        </v-flex>
                        <v-flex xs12 md6>
                            <v-text-field v-model="input.pagarme.machine" label="Código máquina" />
                        </v-flex>
                        <v-flex xs12 md6>
                            <v-text-field v-model="input.pagarme.serviceRefererName" label="ServiceRefererName" />
                        </v-flex>
                        <v-flex xs12 md6>
                            <v-text-field v-model="input.pagarme.splitIncharge" label="Recebedor principal" />
                        </v-flex>
                        <v-flex xs12 md6>
                            <v-text-field v-model="input.pagarme.splitPartner" label="Recebedor parceiro (Split)" />
                        </v-flex>
                    </v-layout>
                </v-alert>
            </v-flex>
            <v-flex xs12>
                <v-alert border="left" colored-border color="info" elevation="5">
                    <h5 class="info--text">Mercado Pago</h5>
                    <v-layout wrap>
                        <v-flex xs12 md6>
                            <v-text-field v-model="input.mp.public" label="Chave pública" />
                        </v-flex>
                        <v-flex xs12 md6>
                            <v-text-field v-model="input.mp.private" label="Chave privada" />
                        </v-flex>
                    </v-layout>
                </v-alert>
            </v-flex>
            <v-flex xs12>
                <v-btn block color="success" @click="send" :loading="sending" :disabled="sending || !valid">Salvar</v-btn>
            </v-flex>
        </v-layout>
    </v-form>
</template>

<script>
import { mask } from "vue-the-mask";
import policies from "@/assets/json/pricePolicies.json";
import rules from "@/helpers/rules";

export default {
    directives: { mask },

    props: {
        data: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            rules,
            valid: true,
            input: null,
            sending: false,
            policies,
        };
    },

    created() {
        console.log(this.data);
        if (this.data) {
            this.input = { ...this.data };
            if (!this.data.payment || !this.data.payment.pricePolicy) {
                this.input.payment = {
                    pricePolicy: null,
                    price: null,
                    priceKwh: null,
                    payTriggerStop: null,
                    payPartnerTransfer: null,
                };
            }
        } else {
            this.input = {
                incharge: false,
                sendUnlockBeforeConnect: false,
                hubject: false,
                powerMax: null,
                powerControl: false,
                powerFree: null,
                powerFreeTime: null,
                payment: {
                    pricePolicy: null,
                    price: null,
                    priceKwh: null,
                    payTriggerStop: null,
                    payPartnerTransfer: null,
                },
                stripe: {
                    public: null,
                    private: null,
                    partner: null,
                },
                pagarme: {
                    private: null,
                    machine: null,
                    serviceRefererName: null,
                    splitIncharge: null,
                    splitPartner: null,
                },
                mp: {
                    public: null,
                    private: null,
                    partner: null,
                },
            };

        }

        if (this.input.payment && this.input.payment.price) {
            this.input.payment.price = parseFloat(this.input.payment.price).toFixed(2);
        }
        if (this.input.payment && this.input.payment.priceKwh) {
            this.input.payment.priceKwh = parseFloat(this.input.payment.priceKwh).toFixed(2);
        }
        console.log(this.input.stripe)
        if (this.input.stripe == undefined || this.input.stripe.public == undefined) {
            this.input.stripe = {
                public: null,
                private: null,
                partner: null,
            }
        }
        if (this.input.pagarme == undefined || this.input.pagarme.private == undefined) {
            this.input.pagarme = {
                private: null,
                machine: null,
                serviceRefererName: null,
                splitIncharge: null,
                splitPartner: null,
            }
        }
        if (this.input.mp == undefined || this.input.mp.public == undefined) {
            this.input.mp = {
                public: null,
                private: null,
                partner: null,
            }
        }
    },

    methods: {
        send() {
            if (this.$refs.form.validate()) {
                this.sending = true;
                this.$http
                    .put("api/v2/car/configuration", this.input.id, this.input)
                    .then((result) => {
                        this.sending = false;
                        this.$eventHub.$emit("msgSuccess", result.message ? result.message : "Dados salvo com sucesso.");
                        this.$emit("saved");
                    })
                    .catch((error) => {
                        this.sending = false;
                        this.$eventHub.$emit("msgError", error.message ? error.message : "Erro ao salvar dados.");
                    });
            }
        },
    },
};
</script>